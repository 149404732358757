<loader></loader>
<div class="close-vr close-popup" (click)="goBack()" >
    <img src="./assets/img/close.svg" alt="Zamknij">
</div>
<div class="scene-container">
    <a id="entervr" >
        <img id="myEnterVRButton" src="./assets/img/vr.png" alt="Tryb Vr" crossorigin="anonymous">
    </a>

    <a-scene id="main-scene"  cursor="rayOrigin: mouse" vr-mode-ui="enterVRButton: #entervr;" *ngIf="imageBg" loading-screen="dotsColor: #0090d7; backgroundColor: #0e9ee6;">
        <a-sky src="#bg" rotation="0 0 0"></a-sky>
        
        <a-assets>
            <img id="bg" [src]="imageBg" (load)="loadImg()" crossorigin="anonymous"/>
            <img id="info" src="./assets/img/info.png">
            <img id="pin" src="./assets/img/pin.png">
        </a-assets>
        <a-entity *ngFor="let el of cameraRotation" camera="active" id="cameraWrapper" [attr.rotation]="el" >
            <a-camera [attr.rotation]="cameraRotation" wasd-controls-enabled="false" >
                <a-cursor material="color: #0090d7; shader: flat"></a-cursor>
                <a-entity id="clickPosition" position="0 0 -8"></a-entity>
            </a-camera>
        </a-entity>
        <a-plane class="vr-el" *ngFor="let el of vrElements" [attr.position]="el.position" [attr.rotation]="el.rotation" [attr.opacity]="1" [attr.data-id]="el.id" [attr.data-type]="el.src" transparent="true" [attr.src]="'#' + el.src"  [attr.data-travel]=" el.travel ? el.travel : null" width="0.6" height="0.6" material="" geometry="" (click)="clickVrElement(el)"></a-plane>
    </a-scene >
    <div class="popup" *ngFor="let el of vrPopups" [attr.data-i]="el.id">
        <div class="popup-container">
            <div class="close-popup" (click)="closePopup(el.id)">
                <img src="./assets/img/close.svg" alt="Zamknij">
            </div>
            <div class="image-container" [attr.data-id]="el.imageID" *ngIf="el.imageID">
                <img [src]="el.imageSrc">
            </div>
            <div class="text-container" [innerHTML]="el.text" *ngIf="el.text"></div>
        </div>
    </div>

</div>
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AttractionComponent } from './pages/attraction/attraction.component';
import { Gallery2dComponent } from './pages/gallery2d/gallery2d.component';
import { HomeComponent } from './pages/home/home.component';
import { LocalitiesComponent } from './pages/localities/localities.component';
import { LocalityComponent } from './pages/locality/locality.component';
import { MapComponent } from './pages/map/map.component';
import { GalleryvrComponent } from './pages/galleryvr/galleryvr.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'miejscowosci', component: LocalitiesComponent },
  { path: 'miejscowosci/:locality', component: LocalityComponent },
  { path: 'miejscowosci/:locality/galeria-2d', component: Gallery2dComponent },
  { path: 'miejscowosci/:locality/galeria-vr', component: GalleryvrComponent },
  { path: 'miejscowosci/:locality/:attraction', component: AttractionComponent },
  { path: 'miejscowosci/:locality/:attraction/galeria-2d', component: Gallery2dComponent },
  { path: 'miejscowosci/:locality/:attraction/galeria-vr', component: GalleryvrComponent },
  { path: 'map', component: MapComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  
 }

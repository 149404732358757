import { createReducer, on } from '@ngrx/store';
import { setLang } from './../actions/lang.actions';

export const initialState = 'pl';
 
const _LangReducer = createReducer(initialState,
  on(setLang, (state, action) => action.name) ,
);

export function LangReducer(state, action) {
    return _LangReducer(state, action);
}
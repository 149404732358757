import { createReducer, on } from '@ngrx/store';
import { setTranslates } from './../actions/translates.actions';

export const initialState = 'pl';
 
const _TranslatesReducer = createReducer(initialState,
  on(setTranslates, (state, action) => action.name) ,
);

export function TranslatesReducer(state, action) {
    return _TranslatesReducer(state, action);
}
import { createReducer, on } from '@ngrx/store';
import { setAtractions } from './../actions/atractions.actions';

export const initialState = [];
 
const _AtractionsReducer = createReducer(initialState,
  on(setAtractions, (state, action) => action.name) ,
);

export function AtractionsReducer(state, action) {
    return _AtractionsReducer(state, action);
}
import { Component, OnInit, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { setLang } from './../../actions/lang.actions';
import axios from 'axios';
import ApiUrl from './../../services/ApiUrl';
import { getTranslate } from './../../services/getTranslate';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  lang$:Observable<string>
  translates$:Observable<string>

  slidesData = [];
  slides = [];
  currentLang = 'pl';
  translates = null;
  
  @ViewChild('slickModal')
  public slickModal: any;

  constructor(private store: Store<{ lang: string, translates: string }>, private router: Router) { 
    this.lang$ = store.pipe(select('lang'));
    this.translates$ = store.pipe(select('translates'));
    this.router.navigate(['/'], { 
      queryParams: {}
    });
  }

  slideConfig = {
    "slidesToShow": 1, 
    "slidesToScroll": 1, 
    "arrows": false, 
    "dots": true,
    customPaging: function(slider, i) {
      return '<div class="slider-dot" id=' + i + ">&nbsp</div>";
    },
  };

  ngOnInit(): void {
    this.lang$.subscribe(currentLang => this.currentLang = currentLang)
    this.translates$.subscribe(translates => this.translates = translates)
    this.getSlider();
    this.removeVrClass();
  }

  removeVrClass = () => {
    if(typeof window !== "undefined") {
      var item = document.querySelector(".a-fullscreen");
      if(item && item.classList) {
        item.classList.remove("a-fullscreen")
      }
    }
  }

  getSlider = async() => {
    await axios({
      method: 'get',
      url: `${ApiUrl()}/showSliderItems`,
    }).then(async response => {
        let res = JSON.parse(response.request.response);
        this.slidesData = res;
        this.slides = res[this.currentLang];
    })
  }

  handleChangeLang = async( lang:string ) => {
    if(lang !== this.currentLang) {
      await this.store.dispatch(setLang({ name: lang }));
      this.currentLang = lang;
      this.slickModal.unslick();
      this.slides = this.slidesData[lang];
      history.pushState("", document.title, window.location.pathname);
    }
  }

  getString = (stringName) => {
    if(stringName && this.translates && this.currentLang) {
      return getTranslate(stringName, this.translates, this.currentLang);
    }
  }

}

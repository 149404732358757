import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import getDataFromUrl from './../../services/getDataFromUrl.js';
import ApiUrl from './../../services/ApiUrl.js';
import axios from 'axios';

@Component({
  selector: 'app-galleryvr',
  templateUrl: './galleryvr.component.html',
  styleUrls: ['./galleryvr.component.scss']
})
export class GalleryvrComponent implements OnInit {

  constructor(private router: Router,  private location: Location){}

  imageBg: string
  vrElements: Array<object>;
  vrPopups: Array<object>;
  cameraRotation: Array<string> = null;
  vrMode: boolean = false;
  sceneLoaded: boolean = false;

  ngOnInit(): void {
    this.getMainImageVr();
  }


  goBack() {
    this.location.back();
  }
  
  loadImg() {
    setTimeout(() => {
      document.getElementById('custom-loader').classList.remove("block");
    }, 1000);
    this.sceneLoaded = true;
  }


  clickVrElement = async(el) => {
    let getType = el.src;

    if ( getType === 'info' ) {
      let getId = el.id;
      document.querySelector(`[data-i*="${ getId }"]`).classList.add('active');
    }
    if ( getType === 'pin' ) {
      await this.router.navigate([], { 
        queryParams: {
          i: el.travel,
        },
      });
      document.getElementById('custom-loader').classList.add("block");
      await this.getMainImageVr(true);
      
    }
  }
 
  closePopup(id) {
    document.querySelector(`[data-i*="${ id }"]`).classList.remove('active');
  }

  getMainImageVr = async (reload?) => {
    let srcImg = getDataFromUrl('i');
    if(srcImg !== "0")  {
      await axios({
        method: 'post',
        url: `${ApiUrl()}/getMainImageVr`,
        data: {
          imageID: srcImg,
        }
      }).then(async response => {
        this.imageBg = response.data.main_img_url;
          if(reload) {
            this.vrElements = null;
            this.vrPopups = null;
            this.cameraRotation = null;
            document.querySelector('a-sky').setAttribute('src', response.data.main_img_url);
          }
          if ( response.data.content ) {
            let data = JSON.parse( response.data.content )
            if ( data.pins ) {
              this.vrElements = data.pins;
            }

            if ( data.popups ) {
              this.vrPopups = data.popups;
            }

            if ( data.cameraRotation ) {
              if(typeof window !== "undefined") {
                this.changeRotation(data.cameraRotation);
              }
            }else{
              if(typeof window !== "undefined") {
                this.changeRotation("0 0 0");
              }
            }

          }else{
            this.vrElements = null;
            this.vrPopups = null;
            this.cameraRotation = null;
          }
          
          
      })
    }
  }

  changeRotation(value) {
      this.cameraRotation = [value];
  }

}

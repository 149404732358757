import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import axios from 'axios';
import ApiUrl from './../../services/ApiUrl';
import getdataFromUrl from './../../services/getDataFromUrl';
import { Router, ActivatedRoute } from '@angular/router';
import createSlug from './../../services/createSlug';
import { getTranslate } from './../../services/getTranslate';
import { setLang } from './../../actions/lang.actions';

@Component({
  selector: 'locality',
  templateUrl: './locality.component.html',
  styleUrls: ['./locality.component.scss']
})
export class LocalityComponent implements OnInit {
  lang$:Observable<string>
  translates$:Observable<string>
  locals$:Observable<string>

  currentLang = 'pl';
  translates = null;
  postsData = [];
  post = [];
  acceptLangsList = [];
  load = false;
  allLocality = null;

  readMoreBtn$:boolean = false;
  constructor(private router: Router, public activeComponent: ActivatedRoute, private store: Store<{ lang: string, translates: string, locals: string }>) {
    this.lang$ = store.pipe(select('lang'));
    this.translates$ = store.pipe(select('translates'));
    this.locals$ = store.pipe(select('locals'));

    store.select('lang').subscribe((val) => {
      if((window.location.pathname).split("/").length >= 4) {
        //back to home when address have 3 or more slash /
        this.router.navigate(['/'], { 
          queryParams: {}
        });
      }else{
        if(this.load && (this.acceptLangsList).length < 2) {
          //back to home when no translation
          this.router.navigate(['/'], { 
            queryParams: {}
          });
        }
        this.post = [];
        this.post = this.postsData.filter(item => {
          if((item.language).toLowerCase() == val) {
            this.router.navigate([], { 
              queryParams: {
                p: item.id
              }
            });
            return val;
          }
        })[0]
      }
    })

    store.select('locals').subscribe((allLocals) => this.getPost(allLocals))
   }

  ngOnInit() {
    this.lang$.subscribe(currentLang => this.currentLang = currentLang)
    this.translates$.subscribe(translates => this.translates = translates)
    this.locals$.subscribe(localsData => this.allLocality = localsData);
    this.removeVrClass();
  }

  removeVrClass = () => {
    if(typeof window !== "undefined") {
      var item = document.querySelector(".a-fullscreen");
      if(item && item.classList) {
        item.classList.remove("a-fullscreen")
      }
    }
  }

  createSlug = (text) => {
    return createSlug(text);
  }

  getString = (stringName) => {
    return getTranslate(stringName, this.translates, this.currentLang);
  }

  getPost = (allLocals) => {
    var postID = getdataFromUrl('p');
    this.allLocality = allLocals;

    if(this.allLocality) {
      var ThisLocal = this.allLocality.filter(local => local.id == postID);
      if(ThisLocal[0]) {
        this.post = ThisLocal[0];
        this.postsData = ThisLocal;
        this.acceptLangsList = [...this.acceptLangsList, ThisLocal[0].language];
        this.store.dispatch(setLang({ name: (ThisLocal[0].language).toLowerCase() }));
      }

      if(this.postsData[0] && this.postsData[0].equivalent_lang) {
        var ThisLocal2 = this.allLocality.filter(local => local.id == this.postsData[0].equivalent_lang);
        if(ThisLocal2[0]) {
          this.postsData = [...this.postsData, ThisLocal2[0]];
          this.acceptLangsList = [...this.acceptLangsList, ThisLocal2[0].language];
        } 
      }

      // if(this.postsData.length > 1) {
      //   this.postsData.map(item => {
      //     if((item.language).toLowerCase() == this.currentLang) {
      //       this.post = item;
      //     }
      //   })
      // }else{
      //   this.post = this.postsData[0];
      // }
      this.load = true;
    }
  }

}
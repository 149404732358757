<div class="top-banner">
    <div class="img-banner">
        <img src="./assets/img/locality-banner-img.jpg" alt="Pietrowice">
    </div>
    <div class="title-wrap">
        <div class="col">
            <div class="logo">
                <img src="./assets/img/logo.svg" alt="Logo Pietrowice">
            </div>
            <div class="title">
                <h1>{{ getString('locality_header') }}<br /><span>Pietrowice Wielkie</span></h1>
            </div>
        </div>
        <div class="col">
            <div class="logo">
                <img src="./assets/img/logo-cz.svg" alt="Logo Hněvošice">
            </div>
            <div class="title">
                <h1>{{ getString('locality_header') }}<br /><span>Hněvošice</span></h1>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="description-wrap">
        <h2>{{ getString('select_city') }}</h2>
        <p>{{ getString('city_desc') }}</p>
    </div>
    <div class="localities">
        <a class="loc" *ngFor="let local of localities" routerLink="/miejscowosci/{{ createSlug(local.name) }}" [queryParams]="{p: local.id}">
            <div class="img-wrap">
                <img [src]="local.main_image_id.image_original" [srcset]="local.main_image_id.image_small + ' 640w, ' + local.main_image_id.image_medium + ' 1280w, ' + local.main_image_id.image_original + ' 1281w'" [alt]="local.main_image_id.name">
            </div>
            <h2>{{ local.name }}</h2>
        </a>
    </div>
</div>

<div class="ghost"></div>

<div class="start-page">
    <div class="bg">
        <img src="./assets/img/home-page-img.jpg" class="main-bg" alt="Pietrowice">
    </div>
    <div class="container">
        <!-- <div class="logo">
            <img src="./assets/img/logo.svg" alt="Logo Pietrowice">
            <img src="./assets/img/logo-cz.svg" alt="Logo Hněvošice">
        </div> -->
        <div class="logo-ue">
            <div class="col">
                <img src="./assets/img/logo_cz_pl_eu.svg" alt="cz-pl" >
                <p>Projekt jest współfinansowany ze środków Europejskiego Funduszu Rozwoju Regionalnego i budżetu państwa RP "Przekraczamy Granice".</p>
            </div>
        </div>
        <div class="title">
            <h1>{{ getString('community') }}<br /><span>&nbsp;Pietrowice Wielkie <br />i  Hněvošice</span></h1>
        </div>
        <div class="text-slider" *ngIf="slides"> 
            <ngx-slick-carousel class="carousel" 
                                #slickModal="slick-carousel" 
                                [config]="slideConfig">
                <div ngxSlickItem *ngFor="let slide of slides" class="item">
                        <h1>{{ slide.title }}</h1>
                        <p>{{ slide.text }}</p>
                </div>
            </ngx-slick-carousel>
        </div>
        <div class="choose-lang">
            <div class="btn" (click)="handleChangeLang('pl')"  [ngClass]="{'active': (lang$ | async) == 'pl'}">Polski</div>
            <div class="btn" (click)="handleChangeLang('cz')"  [ngClass]="{'active': (lang$ | async) == 'cz'}">Čeština</div>
        </div>
        <div class="next">
            <a class="btn-next" routerLink="/miejscowosci">{{ getString('goto_app') }}</a>
        </div>
    </div>

    <div class="bottom-line"></div>
</div>

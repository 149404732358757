<agm-map 
    [latitude]="defaultPositionMap[0].lat" 
    [longitude]="defaultPositionMap[0].lng"
    [styles]="mapStyle"
    [zoom]="zoom">
    <agm-marker 
    *ngFor="let marker of atractions"
        [latitude]="marker.map_lat" 
        [longitude]="marker.map_lng"
        (markerClick)="openAtraction(marker)"
        [iconUrl]="
        {
          url: './assets/img/icon-marker-yellow.svg',
          scaledSize: {
              width: 40,
              height: 60
          }
        }"
        >
    </agm-marker>
    <agm-marker 
    *ngFor="let marker of localities"
        [latitude]="marker.map_lat" 
        [longitude]="marker.map_lng"
        (markerClick)="markerClicked(marker)"
        [iconUrl]="
        {
          url: './assets/img/icon-marker.svg',
          scaledSize: {
              width: 40,
              height: 60
          }
        }"
        >
        
    </agm-marker>

    <agm-marker *ngFor="let pin of vrPins"
                [latitude]="pin.item.lat" 
                [longitude]="pin.item.lng"
                (markerClick)="openVr(pin)"
                [iconUrl]="
                {
                url: './assets/img/icon-marker-vr.svg',
                scaledSize: {
                    width: 40,
                    height: 60
                }
                }"
                >
    </agm-marker>
   
</agm-map>
<div class="map-navigation" *ngIf="hiddenNavigation">
    <div class="marker-popup">
        <div class="close" (click)="hiddenNavigation = false" *ngIf="hiddenNavigation">
            <img src="./assets/img/close.svg" alt="Zamknij" >
        </div>
        <div class="text" *ngIf="atractionsLocal.length > 0">
            <ul *ngFor="let atraction of atractionsLocal">
                <li><a class="loc" routerLink="/miejscowosci/{{ createSlug(openedLocal.name) }}/{{ createSlug(atraction.name) }}" [queryParams]="{p: atraction.id}">{{atraction.name}}</a></li>
            </ul>
        </div>
        <div class="text" *ngIf="atractionsLocal.length == 0">
            <p>Brak atrakcji</p>
        </div>
    </div>
    <div class="background"></div>
</div>
import { createReducer, on } from '@ngrx/store';
import { setLocalities } from './../actions/localities.actions';

export const initialState = [];
 
const _LocalitiesReducer = createReducer(initialState,
  on(setLocalities, (state, action) => action.name) ,
);

export function LocalitiesReducer(state, action) {
    return _LocalitiesReducer(state, action);
}
const createSlug = (text) => {
    text = text.toLowerCase().split(' ').join('-');
    var i = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖŐòóôõöőÈÉÊËěèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜŰùúûüűÑñŠšŸÿýŽžąćęłńóśżź'.split('');
    var o = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeeCcDIIIIiiiiUUUUUuuuuuNnSsYyyZzazelnoszz'.split('');
    for(let x = 0; x<i.length; x++) {
        text = text.replace(i[x], o[x]);
    }

    return text;
}

export default createSlug;
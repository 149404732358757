import { createReducer, on } from '@ngrx/store';
import { setGoBack } from './../actions/goBack.actions';

export const initialState = ['/'];
 
const _GoBackReducer = createReducer(initialState,
  on(setGoBack, (state, action) => [action.name]) ,
);

export function GoBackReducer(state, action) {
    return _GoBackReducer(state, action);
}
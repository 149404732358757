import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { setLang } from './../../actions/lang.actions';
import { Location } from '@angular/common';
import { getTranslate } from './../../services/getTranslate';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  lang$:Observable<string>
  translates$:Observable<string>

  currentLang = 'pl';
  changeLanguageTo = 'cz';
  translates = null;

  constructor(private router: Router, private store: Store<{ lang: string, translates: string }>, private location: Location) {
    this.lang$ = store.pipe(select('lang'));
    this.translates$ = store.pipe(select('translates'));

    store.select('lang').subscribe((val) => {
      this.changeLanguageTo = val == "pl" ? "cz" : "pl"
    })

   }

  ngOnInit(): void {
    this.lang$.subscribe(currentLang => this.currentLang = currentLang)
    this.translates$.subscribe(translates => this.translates = translates)
  }

  handleChangeLang = async( lang:string ) => {
    await this.store.dispatch(setLang({ name: lang }));
    this.currentLang = lang;
  }

  backPage = () => {
    this.location.back();
  }

  getString = (stringName) => {
    return getTranslate(stringName, this.translates, this.currentLang);
  }

}

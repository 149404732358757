<nav id="nav">
    <ul>
        <li>
            <div class="icon">
                <a (click)="backPage()">
                    <img src="./assets/img/prev-arrow.svg" alt="Wstecz">
                </a>
            </div>
            {{ getString('back') }}
        </li>
        <li class="active">
            <div class="icon">
                <a routerLink="/miejscowosci">
                    <img class="localities" src="./assets/img/pin.svg" alt="Miejscowości">
                </a>
            </div>
            {{ getString('places') }}
        </li>
        <li>
            <div class="icon">
                <a routerLink="/map">
                    <img class="map" src="./assets/img/map.svg" alt="Mapa">
                </a>
            </div>
            {{ getString('map') }}
        </li>
        <li (click)="handleChangeLang(changeLanguageTo)">
            <div class="icon">
                <img src="./assets/img/{{changeLanguageTo}}-lang.svg" alt="Język">
            </div>
            {{ getString('language') }}
        </li>
    </ul>
</nav>
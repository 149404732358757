import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import axios from 'axios';
import { Router } from '@angular/router';
import ApiUrl from './../../services/ApiUrl';
import getdataFromUrl from './../../services/getDataFromUrl';
import { getTranslate } from './../../services/getTranslate';

@Component({
  selector: 'app-attraction',
  templateUrl: './attraction.component.html',
  styleUrls: ['./attraction.component.scss']
})
export class AttractionComponent implements OnInit {
  lang$:Observable<string>
  translates$:Observable<string>
  atractions$:Observable<object>

  currentLang = 'pl';
  translates = null;
  post = null;
  atractions = null;

  readMoreBtn$:boolean = false;

  constructor(private router: Router, private store: Store<{ lang: string, translates: string, locals: string, atractions: object }>) {
    this.lang$ = store.pipe(select('lang'));
    this.translates$ = store.pipe(select('translates'));
    this.atractions$ = store.pipe(select('atractions'));

    store.select('atractions').subscribe((atractions) => this.getPost(atractions))
  }

  ngOnInit(): void {
    this.lang$.subscribe(currentLang => this.currentLang = currentLang)
    this.translates$.subscribe(translates => this.translates = translates)
    this.atractions$.subscribe(atractions => this.atractions = atractions);
    this.removeVrClass();
  }

  removeVrClass = () => {
    if(typeof window !== "undefined") {
      var item = document.querySelector(".a-fullscreen");
      if(item && item.classList) {
        item.classList.remove("a-fullscreen")
      }
    }
  }

  getPost = async(atractions) => {
    var postID = getdataFromUrl('p');
    this.atractions = atractions;
    var res = this.atractions.filter(atr => atr.id == postID);
    this.post = res[0];
  }

  getString = (stringName) => {
    return getTranslate(stringName, this.translates, this.currentLang);
  }

}

<div class="top-banner" *ngIf="post">
    <div class="img-banner">
        <img [src]="post.main_image_id.image_original" [srcset]="post.main_image_id.image_small + ' 640w, ' + post.main_image_id.image_medium + ' 1280w, ' + post.main_image_id.image_original + ' 1281w'" [alt]="post.main_image_id.name">
    </div>
</div>

<div class="container" *ngIf="post">
    <div class="description">
        <h1>{{ post.name }}</h1>
        <p>{{ post.short_description }}</p>
        <p *ngIf="readMoreBtn$">{{ post.description }}</p>
        <div class="btn" (click)="readMoreBtn$ = true" [style.display]="!readMoreBtn$ ? 'flex' : 'none'">{{ getString('read_more') }}</div>
    </div>
    <div class="gallery">
        <h2>{{ getString('gallery') }}</h2>
        <div class="gallery-items">
            <p *ngIf="post.gallery_3d_ids.length > 0">{{ getString('choose_360') }}</p>

            <div class="items">
                <div class="item" *ngFor="let vr of post.gallery_3d_ids" [ngClass]="{'hide': vr.visible != '1' }">
                    <a routerLink="galeria-vr" [queryParams]="{i: vr.id}">
                        <img [src]="vr.preview_image_original" [srcset]="vr.preview_image_small + ' 640w, ' + vr.preview_image_medium + ' 1280w, ' + vr.preview_image_original + ' 1281w'"  [alt]="vr.name">
                    </a>
                </div>
            </div>

            <p *ngIf="post.gallery_2d_ids.length > 0">{{ getString('choose_2d') }}</p>

            <div class="items">
                <div class="item gallerybig" *ngFor="let img of post.gallery_2d_ids; let i = index" [ngClass]="{'hide': img.visible != '1' }">
                    <a routerLink="galeria-2d" [queryParams]="{post: post.id, type: '2', 'image': i}">
                        <img [src]="img.image_original" [srcset]="img.image_small + ' 640w, ' + img.image_medium + ' 1280w, ' + img.image_original + ' 1281w'" [alt]="img.name">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="ghost"></div>
import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import axios from 'axios';
import { Router, NavigationStart } from '@angular/router';
import ApiUrl from './../../services/ApiUrl';
import getdataFromUrl from './../../services/getDataFromUrl';
import { getTranslate } from './../../services/getTranslate';
import getDataFromUrl from './../../services/getDataFromUrl';

@Component({
  selector: 'gallery2d',
  templateUrl: './gallery2d.component.html',
  styleUrls: ['./gallery2d.component.scss']
})
export class Gallery2dComponent implements OnInit {

  activeDescription$:boolean = false;
  hiddenNavigation$:boolean = false;

  lang$:Observable<string>
  translates$:Observable<string>
  atractions$:Observable<object>
  locals$:Observable<object>

  postID = getdataFromUrl('post');
  postType = getDataFromUrl('type');
  currentLang = 'pl';
  translates = null;
  images = [];
  actualImageIndex = Number(getDataFromUrl('image'));
  atractions = null;
  locals = null;
  data = null;
  private routerSub:any;

  constructor(private router: Router, private store: Store<{ lang: string, translates: string, atractions: object, locals: object }>) {
    this.lang$ = store.pipe(select('lang'));
    this.translates$ = store.pipe(select('translates'));
    this.atractions$ = store.pipe(select('atractions'));

    store.select('atractions').subscribe((atractions) => this.getImages(atractions, '2'))
    store.select('locals').subscribe((locals) => this.getImages(locals, '1'))


    this.routerSub = router.events.subscribe( event  => {

      if (event instanceof NavigationStart){
        if ( event.navigationTrigger === "popstate" ) {
          if ( event.url.indexOf('image=') > -1 ) {
            this.changeImage('prev')
          }
        }
      }
    }); 
  }

  ngOnInit(): void {
    this.lang$.subscribe(currentLang => this.currentLang = currentLang)
    this.translates$.subscribe(translates => this.translates = translates)
    // this.getImages();
    this.removeVrClass();
  }

  ngOnDestroy() {
    this.routerSub.unsubscribe();
  }
  removeVrClass = () => {
    if(typeof window !== "undefined") {
      var item = document.querySelector(".a-fullscreen");
      if(item && item.classList) {
        item.classList.remove("a-fullscreen")
      }
    }
  }

  getImages = async(data, type) => {
    if(this.postID && this.postType) {
      if(this.postType == type) {
        this.data = data
        var res = this.data.filter(data => data.id == this.postID);
        if(res[0]) {
          this.images = res[0].gallery_2d_ids;
        }
      }
    }
  }

  getString = (stringName) => {
    return getTranslate(stringName, this.translates, this.currentLang);
  }

  changeImage = (itemClick, newIndex?) => {
    var ImagesLength = (this.images).length -1;
    if(itemClick == "nav") {
      this.actualImageIndex = newIndex;
    }else if(itemClick == "prev") {
      if(this.actualImageIndex == 0) {
        this.actualImageIndex = ImagesLength;
      }else{
        this.actualImageIndex -= 1;
      }
    }else if(itemClick == "next") {
      if(this.actualImageIndex == ImagesLength) {
        this.actualImageIndex = 0;
      }else{
        this.actualImageIndex += 1;
      }
    }
    this.router.navigate([], { 
      queryParams: {
        post: this.postID,
        type: this.postType,
        image: this.actualImageIndex
      }
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getTranslate } from './../../services/getTranslate';
import createSlug from './../../services/createSlug';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-localities',
  templateUrl: './localities.component.html',
  styleUrls: ['./localities.component.scss']
})
export class LocalitiesComponent implements OnInit {
  lang$:Observable<string>
  translates$:Observable<string>
  locals$:Observable<string>

  currentLang = 'pl';
  translates = null;
  localitiesData = null;
  localities = [];

  createSlug = (text) => {
    return createSlug(text);
  }

  constructor(private store: Store<{ lang: string, translates: string, locals: string }>, private sanitizer: DomSanitizer) { 
    this.lang$ = store.pipe(select('lang'));
    this.translates$ = store.pipe(select('translates'));
    this.locals$ = store.pipe(select('locals'));
    store.select('lang').subscribe((val) => {
      if(this.localitiesData) {
        this.localities = this.localitiesData.filter(item => (item.language).toLowerCase() == val);
      }
    })
  }

  ngOnInit(): void {
    this.lang$.subscribe(currentLang => this.currentLang = currentLang);
    this.translates$.subscribe(translates => this.translates = translates);
    this.getLocalities();
    // this.removeVrClass();
  }

  removeVrClass = () => {
    if(typeof window !== "undefined") {
      var item = document.querySelector(".a-fullscreen");
      if(item && item.classList) {
        item.classList.remove("a-fullscreen")
      }
    }
  }

  getString = (stringName) => {
    return getTranslate(stringName, this.translates, this.currentLang);
  }

  getLocalities = async() => {
    this.locals$.subscribe(local => {
      this.localitiesData = local;
      this.localities = this.localitiesData.filter(item => (item.language).toLowerCase() == this.currentLang);
    });
  }

}

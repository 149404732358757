import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AgmCoreModule } from '@agm/core';
import { StoreModule } from "@ngrx/store";

import { LangReducer } from './reducers/lang.reducer';
import { TranslatesReducer } from './reducers/translates.reducer';
import { GoBackReducer } from './reducers/goBack.reducer';
import { LocalitiesReducer } from './reducers/localities.reducer';
import { AtractionsReducer } from './reducers/atractions.reducer';
 
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { LocalitiesComponent } from './pages/localities/localities.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { LocalityComponent } from './pages/locality/locality.component';
import { AttractionComponent } from './pages/attraction/attraction.component';
import { Gallery2dComponent } from './pages/gallery2d/gallery2d.component';
import { MapComponent } from './pages/map/map.component';
import { GalleryvrComponent } from './pages/galleryvr/galleryvr.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LocalitiesComponent,
    NavigationComponent,
    LocalityComponent,
    AttractionComponent,
    Gallery2dComponent,
    MapComponent,
    GalleryvrComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB5atG-WX-6r4L4_4ZqA3KLwKjSycHYqek'
    }),
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    StoreModule.forRoot({lang: LangReducer, translates: TranslatesReducer, goBack: GoBackReducer, locals: LocalitiesReducer, atractions: AtractionsReducer})
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {

}


export const getTranslate = (stringName, strings, lang) => {
    if(stringName && strings && lang && strings.length > 2) {
        let string = strings.map(item => item.key === stringName ? item : null);
        string = string.filter((el) => (el));
    
        let word = `I can't find this translate`;
        if ( string.length > 0 ) {
            string.map(el => word = el[lang]);
            // string.map(el => word = el.value);
        }
        return word;
    }
} 

<div class="page-container" *ngIf="images">

    <div class="top-nav"  >
        <div class="btn" (click)="activeDescription$ = !activeDescription$">
            {{ activeDescription$ ? getString('turn_off_desc') : getString('turn_on_desc') }}
        </div>
    
        <div class="full-screen" (click)="hiddenNavigation$ = true">
            <img src="./assets/img/resize.svg" alt="">
        </div>
    </div>
    
    <div class="image"  [ngClass]="{'modal': hiddenNavigation$ }">
        <img [src]="images[actualImageIndex].src" [alt]="images[actualImageIndex].name">

        <div class="close" (click)="hiddenNavigation$ = false" *ngIf="hiddenNavigation$">
            <img src="./assets/img/close.svg" alt="Zamknij" >
        </div>
    </div>

    <div class="text-description-wrap" [ngClass]="{'active': activeDescription$ }">
        
        <div class="text-description">
            <div class="close-desc" (click)="activeDescription$ = false">
                <img src="./assets/img/close.svg" alt="Zamknij" >
            </div>
            <div class="text">
                <p>
                    {{ images[actualImageIndex].description }}
                </p>
            </div>
            <div class="pagination"> 
                <div class="arrow left" (click)="changeImage('prev')">
                    <img src="./assets/img/prev-arrow-white.svg" alt="Poprzedni">
                </div>
                <div class="pages">
                    <ul>
                        <li *ngFor="let item of [].constructor(images.length); let x = index" [class]="x == actualImageIndex ? 'active' : ''" (click)="changeImage('nav', x)">{{x + 1}}</li>
                    </ul>
                </div>
                <div class="arrow right" (click)="changeImage('next')">
                    <img src="./assets/img/prev-arrow-white.svg" alt="Nastęny">
                </div>
            </div>
        </div>
    </div>

    <div class="gallery-control" *ngIf="images.length > 1">
        <div class="arrow left" (click)="changeImage('prev')">
            <img src="./assets/img/prev-arrow-white.svg" alt="Poprzedni">
        </div>
        <div class="arrow right" (click)="changeImage('next')">
            <img src="./assets/img/prev-arrow-white.svg" alt="Nastęny">
        </div>
    </div>
    

</div>
